import { NgModule } from '@angular/core';

import { I3eModule } from '@i3e';

import { SharedModule } from '@consol/shared';
import { CustomModule } from '@consol/custom';

import { ProjectModule } from '@consol/project';
import { EmployeeModule } from '@consol/employee';
import { VendorModule } from '@consol/vendor';
import { CostItemModule } from '@consol/cost-item';

// service
import { ReportService } from './service';
import { ProjectBoqService } from './service';
// directive
import { SharedReportModule } from './shared.module';

// component
import {
  // DepositPurchaseOrderReportComponent,
  //PurchaseOrderCostReportComponent,
  //TaxPurchaseOrderReportComponent,
  //VatPurchaseOrderReportComponent,
  // WarrantyPurchaseOrderReportComponent,
  // BillingNoteCostReportComponent,
  // DeliveryNoteCostReportComponent,
  // TaxInvoiceCostReportComponent,
  //TaxExpenseReportComponent,
  // TaxRevenueReportComponent,
  //VatExpenseReportComponent,
  // VatRevenueReportComponent,
  // WarrantyExpenseReportComponent,
  // DepositExpenseReportComponent,
  // TaxBillingNoteReportComponent,
  // VatBillingNoteReportComponent,
  // RetentionBillingNoteReportComponent,
  // RetentionRevenueReportComponent,
  // RetentionTaxInvoiceReportComponent,
  // TaxTaxInvoiceReportComponent,
  // VatTaxInvoiceReportComponent,
  // PayMethodPurchaseOrderReportComponent,
  // PayMethodExpenseReportComponent,
  // PayMethodBillingNoteReportComponent,
  // PayMethodTaxInvoiceReportComponent,
  // PayMethodRevenueReportComponent,

  PurchaseCostReportComponent,
  PurchaseVatReportComponent,
  PurchaseTaxReportComponent,
  PurchasePayMethodReportComponent,
  PurchaseWarrantyReportComponent,
  PurchaseDepositReportComponent,

  IncomeCostReportComponent,
  IncomeVatReportComponent,
  IncomeTaxReportComponent,
  IncomeRetentionReportComponent,
  IncomePayMethodReportComponent,
} from './component';

// implement

@NgModule({
  imports: [
    I3eModule,
    SharedModule,
    CustomModule,
    ProjectModule,
    EmployeeModule,
    VendorModule,
    CostItemModule,
    SharedModule,
  ],
  exports: [
    // DepositPurchaseOrderReportComponent,
    //PurchaseOrderCostReportComponent,
    //TaxPurchaseOrderReportComponent,
    //VatPurchaseOrderReportComponent,
    // WarrantyPurchaseOrderReportComponent,
    // BillingNoteCostReportComponent,
    // DeliveryNoteCostReportComponent,
    // TaxInvoiceCostReportComponent,
    //TaxExpenseReportComponent,
    // TaxRevenueReportComponent,
    //VatExpenseReportComponent,
    // VatRevenueReportComponent,
    // WarrantyExpenseReportComponent,
    // DepositExpenseReportComponent,
    // TaxBillingNoteReportComponent,
    // VatBillingNoteReportComponent,
    // RetentionBillingNoteReportComponent,
    // RetentionRevenueReportComponent,
    // RetentionTaxInvoiceReportComponent,
    // TaxTaxInvoiceReportComponent,
    // VatTaxInvoiceReportComponent,
    // PayMethodPurchaseOrderReportComponent,
    // PayMethodExpenseReportComponent,
    // PayMethodBillingNoteReportComponent,
    // PayMethodTaxInvoiceReportComponent,
    // PayMethodRevenueReportComponent,

    PurchaseCostReportComponent,
    PurchaseVatReportComponent,
    PurchaseTaxReportComponent,
    PurchasePayMethodReportComponent,
    PurchaseWarrantyReportComponent,
    PurchaseDepositReportComponent,

    IncomeCostReportComponent,
    IncomeVatReportComponent,
    IncomeTaxReportComponent,
    IncomeRetentionReportComponent,
    IncomePayMethodReportComponent,
  ],
  declarations: [
    // DepositPurchaseOrderReportComponent,
    //PurchaseOrderCostReportComponent,
    //TaxPurchaseOrderReportComponent,
    //VatPurchaseOrderReportComponent,
    // WarrantyPurchaseOrderReportComponent,
    // BillingNoteCostReportComponent,
    // DeliveryNoteCostReportComponent,
    // TaxInvoiceCostReportComponent,
    //TaxExpenseReportComponent,
    // TaxRevenueReportComponent,
    //VatExpenseReportComponent,
    // VatRevenueReportComponent,
    // WarrantyExpenseReportComponent,
    // DepositExpenseReportComponent,
    // TaxBillingNoteReportComponent,
    // VatBillingNoteReportComponent,
    // RetentionBillingNoteReportComponent,
    // RetentionRevenueReportComponent,
    // RetentionTaxInvoiceReportComponent,
    // TaxTaxInvoiceReportComponent,
    // VatTaxInvoiceReportComponent,
    // PayMethodPurchaseOrderReportComponent,
    // PayMethodExpenseReportComponent,
    // PayMethodBillingNoteReportComponent,
    // PayMethodTaxInvoiceReportComponent,
    // PayMethodRevenueReportComponent,

    PurchaseCostReportComponent,
    PurchaseVatReportComponent,
    PurchaseTaxReportComponent,
    PurchasePayMethodReportComponent,
    PurchaseWarrantyReportComponent,
    PurchaseDepositReportComponent,

    IncomeCostReportComponent,
    IncomeVatReportComponent,
    IncomeTaxReportComponent,
    IncomeRetentionReportComponent,
    IncomePayMethodReportComponent,
  ],
  providers: [
    ReportService,ProjectBoqService
  ],
})
export class FinanceReportModule { }
